import React, { useEffect, useState } from 'react';
import './App.css';
import { supabase } from './supabase';
import { AuthError, Session } from '@supabase/supabase-js';
import { Home } from './components/Home';
import Login from './components/Login';

export type User = 'Yohei' | 'Momoko' | '';

const App = () => {
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<User>('');

  useEffect(() => {
    (async () => {
      const { data, error } = await supabase.auth.getSession();
      setSession(data.session);

      supabase.auth.onAuthStateChange((event, session) => {
        setSession(session);
      });
    })();
  }, []);

  useEffect(() => {
    if (session === null) return;
    (async () => {
      const id = await getLoginUserId();
      if (id === process.env.REACT_APP_YOHEI_UID) setUser('Yohei');
      if (id === process.env.REACT_APP_MOMOKO_UID) setUser('Momoko');
    })();
  }, [session]);

  const getLoginUserId = async () => {
    try {
      const { data, error } = await supabase.auth.getUser();
      if (error) throw error;

      return data.user.id;
    } catch (error: unknown) {
      if (error instanceof AuthError) {
        alert(error.message);
      }
      alert(error);
    }
  };

  return <div className="App">{session ? <Home user={user} /> : <Login />}</div>;
};

export default App;
