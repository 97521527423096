import React, { useEffect, useState } from 'react';
import { supabase } from '../supabase';
import {
  Avatar,
  Button,
  ButtonGroup,
  Chip,
  Grid,
  NativeSelect,
  Slider,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider, MobileDatePicker, PickersTextField } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AuthError, PostgrestError } from '@supabase/supabase-js';
import { User } from '../App';
import Calculator from './Calculator';
import { Calculate } from '@mui/icons-material';

interface Prop {
  user: User;
}

export const Home = (props: Prop) => {
  const [categoris, setCategories] = useState([{ id: 0, name: '' }]);

  useEffect(() => {
    if (props.user === '') return;
    (async () => {
      await loadCategories();
    })();
  }, [props.user]);

  const loadCategories = async () => {
    try {
      const { data, error } = await supabase.from('categories').select('id, name').eq('use_user', props.user);
      if (error) throw error;
      console.log(data);
      setCategories(data);
    } catch (error: any) {
      alert('loadCategories error:' + error.message);
    }
  };

  return (
    <>
      <Grid container spacing={1} padding={2}>
        <Grid item container spacing={1}>
          <Grid item xs={8}>
            <NativeSelect fullWidth>
              {categoris.map((category) => (
                <option value={category.id}>{category.name}</option>
              ))}
            </NativeSelect>
          </Grid>
          <Grid item>
            {props.user === 'Yohei' && <Avatar>Y</Avatar>}
            {props.user === 'Momoko' && <Avatar>M</Avatar>}
          </Grid>
        </Grid>

        <Grid item container spacing={2} alignItems={'center'} justifyItems={'start'}>
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                label="日付"
                format="YYYY/MM/DD"
                slotProps={{ textField: { size: 'small', variant: 'standard' } }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item>
            <ButtonGroup variant="outlined" aria-label="Basic button group">
              <Button>←</Button>
              <Button>→</Button>
            </ButtonGroup>
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={6}>
            <TextField fullWidth label="お店" variant="standard" size="small" />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth label="メモ" variant="standard" size="small" />
          </Grid>
        </Grid>

        <Grid item>
          <Calculator />
        </Grid>
      </Grid>
    </>
  );
};
