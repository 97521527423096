import { createClient } from '@supabase/supabase-js';
//import { Database } from '../types/supabase';

type Database = {
  public: {
    Tables: {
      categories: {
        Row: {
          id: number;
          name: string;
          use_user: Database['public']['Enums']['User'];
        };
        Insert: {
          id?: number;
          name?: string;
          use_user: Database['public']['Enums']['User'];
        };
        Update: {
          id?: number;
          name?: string;
          use_user?: Database['public']['Enums']['User'];
        };
        Relationships: [];
      };
      records: {
        Row: {
          category_id: number | null;
          created_at: string;
          id: number;
          memo: string | null;
          paid_at: string;
          payer: Database['public']['Enums']['User'];
          payer_ratio: number;
          price: number;
          shop: string | null;
        };
        Insert: {
          category_id?: number | null;
          created_at?: string;
          id?: number;
          memo?: string | null;
          paid_at?: string;
          payer?: Database['public']['Enums']['User'];
          payer_ratio?: number;
          price?: number;
          shop?: string | null;
        };
        Update: {
          category_id?: number | null;
          created_at?: string;
          id?: number;
          memo?: string | null;
          paid_at?: string;
          payer?: Database['public']['Enums']['User'];
          payer_ratio?: number;
          price?: number;
          shop?: string | null;
        };
        Relationships: [
          {
            foreignKeyName: 'public_records_category_id_fkey';
            columns: ['category_id'];
            isOneToOne: false;
            referencedRelation: 'categories';
            referencedColumns: ['id'];
          }
        ];
      };
    };
    Views: {
      [_ in never]: never;
    };
    Functions: {
      [_ in never]: never;
    };
    Enums: {
      User: 'Yohei' | 'Momoko';
    };
    CompositeTypes: {
      [_ in never]: never;
    };
  };
};

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

export const supabase = createClient<Database>(supabaseUrl!, supabaseAnonKey!);
