// src/Login.jsx
import { useState } from 'react';
import { supabase } from '../supabase';
import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import { AuthError } from '@supabase/supabase-js';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const canClickLogin = email !== '' && password !== '';

  const handleLogin = async () => {
    try {
      const { error } = await supabase.auth.signInWithPassword({
        email: email,
        password: password,
      });
      if (error) throw error;
    } catch (error: unknown) {
      if (error instanceof AuthError) {
        alert(error.message);
      } else {
        alert(error);
      }
    }
  };

  return (
    <Grid container padding={8} alignContent={'center'} justifyContent={'center'}>
      <Stack direction="column" spacing={2}>
        <Grid item>
          <Typography>ログイン</Typography>
        </Grid>
        <Grid item>
          <TextField
            type="email"
            label="Email"
            variant="standard"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            type="password"
            label="Password"
            variant="standard"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Grid>
        <Grid item container justifyContent={'flex-end'}>
          <Grid item>
            <Button variant="contained" disabled={!canClickLogin} onClick={handleLogin}>
              ログイン
            </Button>
          </Grid>
        </Grid>
      </Stack>
    </Grid>
  );
};

export default Login;
