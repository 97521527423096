import React, { useEffect, useState } from 'react';
import { Button, TextField, Grid, InputAdornment, IconButton } from '@mui/material';
import { create, all } from 'mathjs';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import BackspaceTwoToneIcon from '@mui/icons-material/BackspaceTwoTone';

type Operation = '+' | '-' | '*' | '/' | '=';

const math = create(all);
const config = {};

const Calculator: React.FC = () => {
  const [expression, setExpression] = useState<string[]>([]);
  const [display, setDisplay] = useState<string>('0');
  const [preResult, setPreResult] = useState<string>('');

  useEffect(() => {
    setDisplay(expression.join(' '));
    try {
      setPreResult(math.evaluate(expression.join(' ')));
    } catch (error) {
      setPreResult('');
    }
  }, [expression]);

  const handleNumberClick = (number: string) => {
    const lastItem = expression.length > 0 ? expression[expression.length - 1] : '';
    if (!expression.length || isNaN(Number(lastItem))) {
      if (number !== '00' && number !== '0') setExpression([...expression, number]);
    } else {
      expression[expression.length - 1] = lastItem + number;
      setExpression([...expression]);
    }
  };

  const handleOperationClick = (operation: Operation) => {
    if (operation === '=') {
      calculateResult();
    } else {
      if (isNaN(Number(expression[expression.length - 1]))) {
        expression[expression.length - 1] = operation;
      } else {
        setExpression([...expression, operation]);
      }
      setDisplay(operation);
    }
  };

  const handleBackSpaceClick = () => {
    if (expression.length === 0) return;
    if (isNaN(Number(expression[expression.length - 1]))) {
      expression.pop();
    } else {
      const lastItem = expression[expression.length - 1];
      if (lastItem.length === 1) {
        expression.pop();
      } else {
        expression[expression.length - 1] = lastItem.slice(0, -1);
      }
    }
    setExpression([...expression]);
  };

  const calculateResult = () => {
    try {
      const result = math.evaluate(expression.join(' '));
      setDisplay(String(result));
      setExpression([String(result)]);
    } catch (error) {
      setDisplay('Error');
      setExpression([]);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <TextField
          fullWidth
          variant="standard"
          label={preResult}
          InputLabelProps={{
            style: { fontSize: '24px' },
          }}
          value={display}
          inputProps={{ style: { textAlign: 'right', fontSize: '32px', fontFamily: 'Meiryo' } }}
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">
                <IconButton onClick={() => setExpression([])}>
                  <HighlightOffTwoToneIcon />
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => handleBackSpaceClick()}>
                  <BackspaceTwoToneIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item container>
        <Grid item container xs={9}>
          {['7', '8', '9', '4', '5', '6', '1', '2', '3', '00', '0'].map((number) => (
            <Grid item xs={4} key={number}>
              <Button variant="outlined" fullWidth onClick={() => handleNumberClick(number)}>
                {number}
              </Button>
            </Grid>
          ))}
        </Grid>
        <Grid item container xs={3}>
          {['/', '*', '-', '+'].map((op) => (
            <Grid item xs={12} key={op}>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => handleOperationClick(op as Operation)}
              >
                {op}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Button variant="outlined" color="secondary" fullWidth onClick={() => handleOperationClick('=')}>
          =
        </Button>
      </Grid>
    </Grid>
  );
};

export default Calculator;
